<template>
  <div>
    <header-email></header-email>
    <more-email></more-email>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderEmail from "../../components/cloud/email/HeaderEmail";
import ContactFormService from "../../components/tools/ContactFormService";
import MoreEmail from "../../components/cloud/email/MoreEmail";
export default {
  name: "PageWebMail",
  components: {MoreEmail, ContactFormService, HeaderEmail},
};
</script>

<style scoped></style>
