export class Canva {
  creatCanvas(image) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    let cnv;
    canvas.style.cssText = "width:290px;" + "height:45px;";
    // canvas.width = 600;
    canvas.height = 40;
    canvas.id = "next-reload";
    context.font = "30px Arial";
    context.strokeText(image, 5, 27);
    //Create the Box Captcha and Input invisible
    document.getElementById("n-reload").appendChild(this.exportHtml());
    document.getElementById("n-reload").appendChild(this.creatInput());
    //Take element on Form
    cnv = document.getElementById("n-element");
    cnv.appendChild(canvas);
    // cnv.append(this.creatInputText());

  }
  exportHtml() {
    const box = document.createElement("div");
    box.style.cssText =
      "" +
      "background-color: transparent; " +
      "width:210px;" +
      "height:95px;" +
      "border-radius:4px;" +
      "border:0" +
      "";
    box.id = "n-element";
    return box;
  }
  creatInput() {
    const input = document.createElement("input");
    input.style.cssText = "display:none";
    input.id = "n-honeypot";
    return input;
  }

  creatInputText() {
    const input = document.createElement("input");
    input.style.cssText = "display:block; margin-top: 0px; z-index:50";
    input.id = "type-text";
    input.type = "text";
    input.name = "nextReload";
    return input;
  }
}
