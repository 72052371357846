<template>
  <div class="">
    <v-app-bar
      app
      fixed
      style="margin-top: 40px; padding-left: 130px; background-color: white"
      height="80"
    >
      <img
        @click="redirectHome"
        style="max-width: 80px; cursor: pointer"
        alt=""
        :src="require('../../assets/img/logo_.png')"
      />
      <div style="padding-left: 15px; padding-right: 15px"></div>

      <v-btn
        class="text-capitalize font-weight-bold btn-hover hidden-sm-and-down"
        color="rgb(11,39,49)"
        text
        x-large
        @click.stop="showServiceMenu"
      >
        Serviços
      </v-btn>

      <v-btn
        class="text-capitalize font-weight-bold hidden-sm-and-down"
        color="rgb(11,39,49)"
        text
        x-large
        @click.stop="showProductMenu"
      >
        Planos
      </v-btn>
      <v-btn
        class="text-capitalize font-weight-bold hidden-sm-and-down"
        color="rgb(11,39,49)"
        text
        x-large
        style="text-transform: none !important"
        @click="$vuetify.goTo(target, options)"
      >
        Apoio ao Cliente
      </v-btn>
      <v-spacer></v-spacer>

      <div
        style="padding-right: 65px"
        class="d-sm-none d-md-flex hidden-sm-and-down"
      >
        <v-btn
          class="text-capitalize font-weight-bold"
          color="black"
          text
          x-large
          @click="myAccount"
        >
          <v-icon> mdi-account </v-icon>
          <span>Cliente</span>
        </v-btn>
      </div>
    </v-app-bar>

    <!--    DRAWER Services-->
    <service-menu></service-menu>
    <product-menu></product-menu>
  </div>
</template>

<script>
import eventBus from "../../main";
import ServiceMenu from "./ServiceMenu";
import ProductMenu from "./ProductMenu";
export default {
  name: "SecundaryMenu",
  components: { ProductMenu, ServiceMenu },
  data: () => ({
    selector: "#contact",
    duration: 900,
    offset: 0,
    easing: "easeInOutCubic",
    drawer: false,
    drawerProduct: false,
    // group: null,
  }),
  methods: {
    myAccount() {
      return this.$store.dispatch("myAccount");
    },
    redirectHome() {
      return this.$router.push("/");
    },
    showServiceMenu() {
      this.drawer = true;
      eventBus.$emit("serviceMenu", this.drawer);
    },
    showProductMenu() {
      this.drawerProduct = true;
      eventBus.$emit("productMenu", this.drawerProduct);
    },
  },
  computed: {
    target() {
      return this.selector;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },
  // watch: {
  //   group () {
  //     this.drawer = false
  //   },
  // },
};
</script>

<style scoped>
#google_translate_element select {
  background-color: #000000; /* Cor de fundo preta */
  color: #fff; /* Cor do texto branca */
  border: 1px solid #ccc; /* Borda cinza clara */
  padding: 5px; /* Espaçamento interno */
  border-radius: 5px; /* Bordas arredondadas */
  font-size: 14px; /* Tamanho da fonte */
}
.theme--light.v-btn:hover::before {
  opacity: 0;
}

.theme--light.v-btn:hover::after {
  opacity: 0;
}
.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover)
  .v-btn__content {
  opacity: 0;
}
.v-ripple_container {
}
</style>
