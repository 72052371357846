<template>
  <div>
    <header-net-fast></header-net-fast>
    <more-net-fast></more-net-fast>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderNetFast from "../components/internet/netfast/HeaderNetFast";
import MoreNetFast from "../components/internet/netfast/MoreNetFast";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageNetFast",
  components: { ContactFormService, MoreNetFast, HeaderNetFast },
};
</script>

<style scoped></style>
