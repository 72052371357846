<template>
  <div class="mb-12 bg-sec-support-e" style="margin-top: -50px" id="paas">
    <div style="width: 100%">
      <v-row
        class="fill-height mt-12"
        align="center"
        justify="center"
        no-gutters
      >
        <!--First Spoiller-->
        <v-col lg="6">
          <div
            style="
              min-height: 600px;
              background-color: #235db5;
              padding: 50px;
            "
          >
            <div class="sans spoiler-size call-wid">
              <strong style="color: white !important"
                >Porque escolher a nossa PaaS (Plataforma as a Service)</strong
              >
            </div>

            <v-card-text class="spoiler-description call-wid">
              Plataforma completa em núvem para desenvolvimento, execução e
              organização de aplicativos sem o custo, a complexidade e a falta
              de flexibilidade que muitas vezes acompanha a implementação e a
              manutenção dessa plataforma em ambiente local.
            </v-card-text>
<!--            <v-card-text class="spoiler-description call-wid"> </v-card-text>-->
          </div>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="6">
          <div style="min-height: 600px" class="pa-2 bg-support m-txt-center">
            <div class="bg-support"></div>
          </div>
        </v-col>

        <!--Third Spoiller-->
      </v-row>
      <div style="margin-top: -25px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionPaas",
  data: () => ({
    selector: "#paas",
    duration: 700,
    offset: 0,
    easing: "easeInOutCubic"
  }),
  computed: {
    target() {
      return this.selector;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      };
    }
  }
};
</script>

<style scoped>
.bg-sec-support-e {
  background: #efefef;
}
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #000000;
  /*line-height: 1;*/
  font-size: 28px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff !important;
  line-height: 1.2;
}

.bg-support {
  background-image: url("https://img.freepik.com/free-photo/cloud-storage-banner-background_53876-108506.jpg");
  /*background-image: url("https://img.freepik.com/free-photo/hologram-projector-screen-with-cloud-system-technology_53876-108502.jpg");*/
  background-size: cover;
  background-color: #efefef;
}
</style>
