<template>
<component-iaas></component-iaas>
</template>

<script>
import ComponentIaas from "../../components/cloud/iaas/ComponentIaas";
export default {
  name: "PageIaas",
  components: {ComponentIaas}
}
</script>

<style scoped>

</style>