import Vue from "vue";
import Vuex from "vuex";
import account from "./modules/webpage";
import routes from "./modules/webpage/routes";
import next from "./modules/next";
import nsreload from "./modules/nsreload"
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    account,
    routes,
    next,
      nsreload
  },
});
