import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import PageEnterprise from "../views/PageEnterprise";
import Aplication from "../views/Aplication";
import PageInstitutional from "../views/PageInstitutional";
import PageNetFast from "../views/PageNetFast";
import PageNetSat from "../views/PageNetSat";
import PageNetPremium from "../views/PageNetPremium";
import PageVoip from "../views/PageVoip";
import PageSipTrunk from "../views/PageSipTrunk";
import PageIPBX from "../views/PageIPBX";
import PageMpls from "../views/mpls/PageMpls";
import PageWebMail from "../views/webmail/PageWebMail";
import PageHosting from "../views/webhosting/PageHosting";
import PageIaas from "../views/iaas/PageIaas";
import PageFirewall from "../views/firewall/PageFirewall";
import PageAntiVirus from "../views/anti_virus/PageAntiVirus";
import PageDatabase from "../views/database/PageDatabase";

Vue.use(VueRouter);
Vue.use(VueMeta);
const routes = [
  {
    path: "*",
    name: "NotFound",
    component: () =>
      import(
        /* webpackChunkName : NotFound */
        "../views/NotFound.vue"
      ),
  },
  {
    path: "/",
    name: "particular",
    component: Aplication,
    children: [
      {
        path: "/enterprise",
        name: "enterprise",
        component: PageEnterprise,
      },
      {
        path: "/institutional",
        name: "institutional",
        component: PageInstitutional,
      },
      {
        path: "/internet/net-fast",
        name: "internet.netfast",
        component: PageNetFast,
      },
      {
        path: "/internet/net-sat",
        name: "internet.netsat",
        component: PageNetSat,
      },
      {
        path: "/internet/net-premium",
        name: "internet.netpremium",
        component: PageNetPremium,
      },
      {
        path: "/enterprise/service/voip",
        name: "enterprise.voip",
        component: PageVoip,
      },
      {
        path: "/enterprise/service/sip",
        name: "enterprise.sip.trunk",
        component: PageSipTrunk,
      },
      {
        path: "/enterprise/service/ip-pbx",
        name: "enterprise.pbx",
        component: PageIPBX,
      },
      {
        path: "/enterprise/cloud/mpls",
        name: "enterprise.cloud.mpls",
        component: PageMpls,
      },
      {
        path: "/enterprise/cloud/email-hosting",
        name: "enterprise.cloud.email",
        component: PageWebMail,
      },
      {
        path: "/enterprise/cloud/web-hosting",
        name: "enterprise.cloud.web.hosting",
        component: PageHosting,
      },
      {
        path: "/enterprise/cloud/iaas",
        name: "enterprise.cloud.iaas",
        component: PageIaas,
      },

        {
            path: "/enterprise/cloud/firewall",
            name: "enterprise.cloud.firewall",
            component: PageFirewall,
        },
        {
            path: "/enterprise/cloud/antivirus",
            name: "enterprise.cloud.antivirus",
            component: PageAntiVirus,
        },
        {
            path: "/enterprise/cloud/database",
            name: "enterprise.cloud.database",
            component: PageDatabase,
        }
    ],
  },
];

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.afterEach(to => {
//     Vue.nextTick(() => {
//         document.title = to.meta.title || "Netspace";
//     });
// });

export default router;
