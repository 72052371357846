<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    bottom
    temporary
    width="400"
    height="100%"
  >
    <v-list class="font-weight-bold">
      <v-list-item-group
        v-model="group"
        active-class="primary--text darken-4 text--accent-4"
      >
        <v-list-item style="margin-top: 50px" @click="databaseInfo">
          <v-list-item-title
            >Cloud Database
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            ></v-list-item-title
          >
        </v-list-item>

        <v-list-item @click="antiVirusInfo">
          <v-list-item-title
            >Cloud Anti-vírus
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="firewallInfo">
          <v-list-item-title
            >Cloud Firewall
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="paas">
          <v-list-item-title
            >PaaS (platform as a service)
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="iaas">
          <v-list-item-title
            >IaaS (infrastractur as a service)
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="webHosting">
          <v-list-item-title
            >Web hosting
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="emailHosting">
          <v-list-item-title
            >Web E-mail
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "ServiceMenu",
  data: () => ({
    selector: "#paas",
    duration: 900,
    offset: 0,
    easing: "easeInOutCubic",

    drawer: false,
    group: null,
  }),
  methods: {
    myAccount() {
      return this.$store.dispatch("myAccount");
    },
    redirectHome() {
      return this.$router.push("/");
    },
    firewallInfo() {
      return this.$store.dispatch("firewallInfo");
    },
    antiVirusInfo() {
      return this.$store.dispatch("antiVirusInfo");
    },
    databaseInfo() {
      return this.$store.dispatch("databaseInfo");
    },

    emailHosting() {
      return this.$store.dispatch("emailHosting");
    },
    webHosting() {
      return this.$store.dispatch("webHosting");
    },
    iaas() {
      return this.$store.dispatch("iaas");
    },
    paas() {
      return this.$router.push("/enterprise");
    },
  },

  computed: {
    target() {
      return this.selector;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing,
      };
    },
  },

  watch: {
    group() {
      this.drawer = false;
    },
  },
  created() {
    const self = this;
    eventBus.$on("serviceMenu", function (payload) {
      self.drawer = payload;
    });
  },
};
</script>

<style scoped></style>
