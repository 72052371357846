<template>
  <div class="mb-12 bg-sec-support-e" style="margin-top: -50px">
    <div style="width: 100%">
      <v-row
        class="fill-height mt-12"
        align="center"
        justify="center"
        no-gutters
      >
        <!--First Spoiller-->

        <v-col lg="6">
          <div style="min-height: 600px" class="pa-2 bg-support m-txt-center">
            <div class="bg-support"></div>
          </div>
        </v-col>

        <!--Second Spoiller #235db5-->

        <v-col lg="6">
          <div
            style="min-height: 600px; background-color: #616266; padding: 50px"
          >
            <div class="sans spoiler-size call-wid">
              <strong style="color: white !important"
                >Tour Ciclismo Netspace - Kilamba</strong
              >
            </div>

            <v-card-text class="spoiler-description call-wid">
              Sentimo-nos felizes por realizar com sucesso o Tour Ciclismo
              Netspace. Sentimo-nos felizes por agregar Tecnologia e desporto.
            </v-card-text>
            <v-card-text class="spoiler-description call-wid"> </v-card-text>
          </div>
        </v-col>
        <!--Third Spoiller-->
      </v-row>

      <!--      SECONF LINE-->
      <v-row
        class="fill-height mt-0"
        align="center"
        justify="center"
        no-gutters
      >
        <!--First Spoiller-->

        <v-col lg="6">
          <div
            style="min-height: 600px; background-color: #616266; padding: 50px"
          >
            <div class="sans spoiler-size call-wid">
              <strong style="color: white !important"
                >Exposição na Filda 2022</strong
              >
            </div>

            <v-card-text class="spoiler-description call-wid">
              Mais uma participação na maior Feira De Luanda. Sentimos
              felicidade no rosto de quem nos visitou, por isto, a equipa
              Netspace está de parabéns.
            </v-card-text>
            <v-card-text class="spoiler-description call-wid"> </v-card-text>
          </div>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="6">
          <div style="min-height: 600px" class="pa-2 bg-slide2 m-txt-center">
            <div class="bg-slide2"></div>
          </div>
        </v-col>

        <!--Third Spoiller-->
      </v-row>

      <div style="margin-top: -25px"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionInstitutionalA",
};
</script>

<style scoped>
.bg-sec-support-e {
  background: #efefef;
}
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #000000;
  /*line-height: 1;*/
  font-size: 28px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff !important;
  line-height: 1.2;
}

.bg-support {
  background-image: url("../../../assets/img/premium_net.png");
  background-size: cover;
  background-color: #efefef;
}

.bg-slide2 {
  background-image: url("../../../assets/img/filda.png");
  background-size: cover;
  background-color: #efefef;
}
</style>
