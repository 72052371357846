

export default {
  state: {
    ns: ""
  },
  mutations: {
    SET_NEXT_RELOAD(state, value) {
      state.ns = value;
    }
  },
  actions: {
    //End Actions
  },

  getters: {
    getNs(state) {
      return state.ns;
    }
  }
};
