<template>
  <div class="mb-12 bg-sec-a">
    <v-container>
      <v-card
        class="mx-auto m-txt-center transparent mt-12"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto" style="max-width: 400px">
          <strong style="color: rgb(23, 87, 109) !important"
            >As melhores soluções para a sua Empresa</strong
          >
        </div>
        <div
          style="overflow-wrap: break-word !important"
          class="description mx-auto mt-6"
          data-aos="fade-up"
        >
          Criamos soluções robustas para atender às empresas, desde web e-mail à
          Cloud database.
        </div>
      </v-card>
      <v-row class="fill-height mt-12" align="center" justify="center">
        <!--First Spoiller-->

        <v-col lg="4">
          <v-card
            class="pa-2 draw-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <v-img height="200px" class="bx">
              <div class="sans spoiler-size call-wid mt-10">
                <strong style="color: white !important">Web E-mail</strong>
              </div>
            </v-img>
            <v-card-text
              class="spoiler-description call-wid"
              style="overflow-wrap: break-word !important"
            >
              Crie, hospede sem burocracia os e-mails da sua empresa. O nosso
              serviço de web e-mail serve para todas as empresas.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div class="">
                <v-btn @click="emailHosting" large>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 office-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <v-img height="200px" class="by">
              <div class="sans spoiler-size call-wid mt-10">
                <strong style="color: white !important">Web Hosting</strong>
              </div>
            </v-img>
            <v-card-text class="spoiler-description call-wid">
              Crie, hospede o website da sua empresa sem complicações. O nosso
              serviço de web e-mail serve para todas as empresas.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div>
                <v-btn @click="webHosting" large>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!--Third Spoiller-->
        <v-col lg="4" md="auto">
          <v-card
            class="pa-2 ofmail-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="400"
          >
            <v-img height="200px" class="bz">
              <div class="sans spoiler-size call-wid mt-10">
                <strong style="color: white !important">IAAS</strong>
              </div>
            </v-img>
            <v-card-text class="spoiler-description call-wid">
              Crie recursos essenciais de computação em núvem como armazenamento
              e rede sob demanda.
            </v-card-text>
            <v-card-text data-aos="fade-up">
              <div>
                <v-btn @click="iaas" large>Saiba mais</v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!--        <v-card-text class="m-txt-center m-center mt-12">-->
        <!--          <v-btn-->
        <!--            @click="urlStringAccount"-->
        <!--            large-->
        <!--            class="ma-2"-->
        <!--            rounded-->
        <!--            >Criar conta</v-btn-->
        <!--          >-->
        <!--          &lt;!&ndash;          style="text-transform: none"&ndash;&gt;-->
        <!--        </v-card-text>-->
      </v-row>
      <div style="margin-top: 80px; background-color: #efefef"></div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionEnterpriseA",
  methods: {
    emailHosting() {
      return this.$store.dispatch("emailHosting");
    },
    webHosting() {
      return this.$store.dispatch("webHosting");
    },
    iaas() {
      return this.$store.dispatch("iaas");
    },
  },
};
</script>

<style scoped>
.bg-sec-a {
  background: #efefef;
}
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #000000;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #000000 !important;
  line-height: 1.2;
}

.bx {
  background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),
    url("https://img.freepik.com/free-photo/futuristic-smart-city-with-5g-global-network-technology_53876-98438.jpg");
  background-size: cover;
}
.by {
  background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),
    url("https://img.freepik.com/free-photo/cloud-computing-banner-background-smart-city_53876-108504.jpg");
  background-size: cover;
}
.bz {
  background-image: linear-gradient(rgba(15, 15, 16, 0.5), rgb(0, 0, 0)),
    url("https://img.freepik.com/free-photo/blue-optic-fiber-with-ethernet-cables_23-2148779311.jpg");
  background-size: cover;
}
</style>
