<template>
  <div>
    <component-banner-service
      title="Conheça o serviço"
      bold-title="Cloud Firewall"
      description="Este serviço forma uma barreira virtual em torno de plataformas, infraestrutura e aplicativos em nuvem."
      :my-style="dt"
    ></component-banner-service>
    <component-description-service
      title="Mais sobre o serviço Cloud Firewall"
      description="Criamos uma barreira virtual em torno de plataformas, infraestrutura e softwares em nuvem. O nosso serviço de firewalls na nuvem também pode proteger infraestruturas locais."
      opt1="Disponibilidade"
      desc-opt1="Inclui SLA com disponibilidade de 99,995%, e a possibilidade de definir soluções de redundância à medida"
      opt2="Escalabilidade"
      desc-opt2="Ampliie ou reduza sua infraestrutura conforme necessário em minutos."
      opt3="Mobilidade"
      desc-opt3="Facilidade de acesso. Aceda aos seus serviços a prtir de qualquer lugar."
    ></component-description-service>
  </div>
</template>

<script>
import ComponentBannerService from "../../common/ComponentBannerService";
import ComponentDescriptionService from "../../common/ComponentDescriptionService";
export default {
  name: "ComponentFirewall",
  components: { ComponentDescriptionService, ComponentBannerService },
  data: () => ({
    dt: "background-image: linear-gradient(295deg,rgba(15, 15, 16, 0.68),rgb(0, 0, 0)),url('https://img.freepik.com/free-vector/data-protection-background-vector-cyber-security-technology-purple-tone_53876-136341.jpg');background-size: cover;background-color: #efefef;",
  }),
};
</script>

<style scoped></style>
