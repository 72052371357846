<template>
  <div>
    <header-pbx></header-pbx>
    <more-pbx></more-pbx>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderPbx from "../components/voice/pbx/HeaderPbx";
import MorePbx from "../components/voice/pbx/MorePbx";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageIPBX",
  components: {ContactFormService, MorePbx, HeaderPbx },
};
</script>

<style scoped></style>
