<template>
  <div>
    <header-voip></header-voip>
    <more-voip></more-voip>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderVoip from "../components/voice/voip/HeaderVoip";
import MoreVoip from "../components/voice/voip/MoreVoip";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageVoip",
  components: { ContactFormService, MoreVoip, HeaderVoip },
};
</script>

<style scoped></style>
