<template>
  <div>
    <component-data-base></component-data-base>
  </div>
</template>

<script>
import ComponentDataBase from "../../components/cloud/database/ComponentDataBase";
export default {
  name: "PageDatabase",
  components: { ComponentDataBase },
};
</script>

<style scoped></style>
