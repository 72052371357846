<template>
  <div class="filter m-border-line-1">
    <v-container>
      <v-card
        width="320"
        class="transparent"
        elevation="0"
        style="padding-top: 100px !important"
      >
        <h1 class="">
          <span class="mx-auto text-center text-header white--text m-txt-center"
            >Soluções de MPLS-VPN</span
          >
        </h1>
      </v-card>

      <v-card-text
        style="width: 400px; font-size: 20px !important;"
        class="mt-12 white--text text-subtitle-1 text-sub-header margin-line font-weight-regular mx-n4"
      >
        O serviço de VPN da Netspace é
        uma solução altamente segura e
        resistente para redes privadas
        virtuais, baseada em IP.
      </v-card-text>
      <v-row>
        <div class="mx-3 mt-12">
          <v-btn
            x-large
            outlined
            color="white"
            dark
            @click="mplsInfo"
            class="text-capitalize"
            >Saiba mais
          </v-btn>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "HeaderContentCover",
  methods:{
    mplsInfo() {
      return this.$store.dispatch("mplsInfo");
    },
  }
};
</script>

<style scoped>
.text-header {
  font-family: "Fira Sans Condensed", sans-serif;
  line-height: 42px;
  font-size: 38px;
  font-weight: 500;
}

.filter {
  /*background: linear-gradient(rgba(0, 0, 0, 0), #8bc53f, #1b75bb) !important;*/
  background: linear-gradient(
    269deg,
    rgba(2, 0, 36, 1),
    rgb(0 0 0) 35%,
    #1b92ac 100%
  ) !important;
  opacity: 0.7;
  margin-top: -200px;
  /*height: 634px;*/
  max-height: 700px;
  height: 630px;
}
</style>
