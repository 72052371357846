<template>
  <div>
    <component-anti-virus></component-anti-virus>
  </div>
</template>

<script>
import ComponentAntiVirus from "../../components/cloud/anti_virus/ComponentAntiVirus";
export default {
  name: "PageAntiVirus",
  components: { ComponentAntiVirus },
};
</script>

<style scoped></style>
