<template>
  <div class="mb-12 bg-ef" style="width: 100%; height: 400px" id="contact">
    <v-container style="max-width: 80%">
      <v-row class="fill-height mt-0" align="center" justify="center">
        <!--First Spoiller-->

        <v-col lg="4">
          <v-row>
            <v-col lg="2">
              <v-card
                class="pa-2 draw-svg m-txt-center transparent"
                color="#ffffff"
                elevation="0"
              >
                <div class="spoiler-description call-wid">
                  <v-icon size="56">mdi-map-marker</v-icon>
                </div>
              </v-card>
            </v-col>
            <v-col lg="8">
              <v-card
                class="pa-2 draw-svg m-txt-center transparent"
                color="#ffffff"
                elevation="0"
                max-height="200"
              >
                <div class="sans spoiler-size call-wid">
                  <strong>Endereço</strong>
                </div>
                <v-card-text
                  class="spoiler-description call-wid"
                  style="overflow-wrap: break-word !important"
                >
                  Condomínio Alpha Escritórios, Rua do MAT, Via C3 Edifício 4
                  Piso 0 Talatona – Luanda, Caixa Postal: 3087
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="4">
          <v-row>
            <v-col lg="2">
              <v-card
                class="pa-2 draw-svg m-txt-center transparent"
                color="#ffffff"
                elevation="0"
              >
                <div class="spoiler-description call-wid">
                  <v-icon size="56">mdi-phone</v-icon>
                </div>
              </v-card>
            </v-col>
            <v-col lg="8">
              <v-card
                class="pa-2 draw-svg m-txt-center transparent"
                color="#ffffff"
                elevation="0"
                max-height="200"
              >
                <div class="sans spoiler-size call-wid">
                  <strong>Telefones</strong>
                </div>
                <v-card-text
                  class="spoiler-description call-wid"
                  style="overflow-wrap: break-word !important"
                >
                  +244 223 030 000  +244 945 797 373
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!--Third Spoiller-->
        <v-col lg="4">
          <v-row>
            <v-col lg="2">
              <v-card
                class="pa-2 draw-svg m-txt-center transparent"
                color="#ffffff"
                elevation="0"
              >
                <div class="spoiler-description call-wid">
                  <v-icon size="56">mdi-email</v-icon>
                </div>
              </v-card>
            </v-col>
            <v-col lg="9">
              <v-card
                class="pa-2 draw-svg m-txt-center transparent"
                color="#ffffff"
                elevation="0"
                max-height="200"
              >
                <div class="sans spoiler-size call-wid">
                  <strong>E-mail</strong>
                </div>
                <v-card-text
                  class="spoiler-description call-wid"
                  style="overflow-wrap: break-word !important"
                >
                  geral@netspace.co.ao
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <!--        <v-card-text class="m-txt-center m-center mt-12">-->
        <!--          <v-btn-->
        <!--            @click="urlStringAccount"-->
        <!--            large-->
        <!--            class="ma-2"-->
        <!--            rounded-->
        <!--            >Criar conta</v-btn-->
        <!--          >-->
        <!--          &lt;!&ndash;          style="text-transform: none"&ndash;&gt;-->
        <!--        </v-card-text>-->
      </v-row>

    </v-container>

    <div style="width: 100%; background-color: #2d2b2b; margin-top: 200px">
      <v-card class="transparent" elevation="0">
        <v-card-text class="mx-auto m-txt-center" style="color: #f6f6f6">
          Todos os Direitos Reservados à NetSpace | Desenvolvido por DELMAWAVE
        </v-card-text>
      </v-card>
    </div>

  </div>
</template>

<script>
export default {
  name: "SectionContact",
  data: () => ({
    selector: "#contact",
    duration: 700,
    offset: 0,
    easing: "easeInOutCubic"
  }),

  computed: {
    target() {
      return this.selector;
    },
    options() {
      return {
        duration: this.duration,
        offset: this.offset,
        easing: this.easing
      };
    }
  }
};
</script>

<style scoped>
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: #000000 !important;
  line-height: 1.2;
  text-align: left !important;
}
/*.bg-svg {*/
/*  background-image: linear-gradient(*/
/*      rgba(15, 15, 16, 0.5),*/
/*      rgb(23, 87, 109),*/
/*      rgb(0, 0, 0)*/
/*    ),*/
/*    url("");*/
/*  background-size: cover;*/
/*}*/

.bg-ef {
  /*background-color: #efefef;*/
  background-color: #ffffff;
  /*background-image: url("https://cdn-gcpjb.nitrocdn.com/zDjUVtKwCNFRTvnNiXhNTehtxImYIiQK/assets/static/optimized/rev-cf99951/satenet/wp-content/uploads/sites/3/2019/12/background-with-dots.png");*/
  background-image: url("../../../assets/img/map_dots.png");
  background-size: 50%;
  background-position: center;
}
</style>
