export class Character {
  displayNext() {
    return [];
  }

  numberChar() {
    return 7;
  }
  setCharacter() {
    return "0123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ@#$&*!";
  }

  rendResult() {
    const display = this.displayNext();
    //Count number of character and escape repetition this Characters
    for (let i = 0; i < this.numberChar(); i++) {
      const position = Math.floor(Math.random() * this.setCharacter().length);
      if (display.indexOf(this.setCharacter()[position]) === -1) {
        display.push(this.setCharacter()[position]);
      } else {
        i--;
      }
    }
    return display;
  }
}
