import { render, staticRenderFns } from "./PageWebMail.vue?vue&type=template&id=e0aa99d4&scoped=true&"
import script from "./PageWebMail.vue?vue&type=script&lang=js&"
export * from "./PageWebMail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e0aa99d4",
  null
  
)

export default component.exports