<template>
  <div>
    <header-net-premium></header-net-premium>
    <more-net-premium></more-net-premium>
    <contact-form-service></contact-form-service>
  </div>
</template>

<script>
import HeaderNetPremium from "../components/internet/premium/HeaderNetPremium";
import MoreNetPremium from "../components/internet/premium/MoreNetPremium";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageNetPremium",
  components: { ContactFormService, MoreNetPremium, HeaderNetPremium },
};
</script>

<style scoped></style>
