<template>
  <div class="mb-12 mt-n6 bg-more-support" style="width: 100%">
    <v-container>
      <v-card
        class="mx-auto m-txt-center transparent mt-12"
        width="700"
        elevation="0"
      >
        <div class="sans title-size mx-auto" style="max-width: 400px">
          <strong style="color: rgb(23, 87, 109) !important"
            >Estamos conectados a vários canais</strong
          >
        </div>
        <div
          style="overflow-wrap: break-word !important"
          class="description mx-auto mt-6"
          data-aos="fade-up"
        >
          Esteja por dentro de todas às informações/actividades da NetSpace acompanhado
          os nossos canais digitais.
        </div>
      </v-card>
      <v-row class="fill-height mt-12" align="center" justify="center">
        <!--First Spoiller-->

        <v-col lg="3">
          <v-card
            class="pa-2 draw-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="200"
          >
            <div class="sans spoiler-size call-wid">
              <strong
                ><v-icon size="60" color="rgb(23, 87, 109)"
                  >mdi-linkedin</v-icon
                ></strong
              >
            </div>
            <v-card-text
              class="spoiler-description call-wid mt-n6"
              style="overflow-wrap: break-word !important"
            >
              <!--              <span data-aos="fade-up">Estar por dentro</span>-->
              <div class="mt-n6" data-aos="fade-up">
                <v-btn
                  @click="linkedin"
                  text
                  color="rgb(23, 87, 109)"
                  style="
                    text-transform: none;
                    font-size: 18px;
                    letter-spacing: -0.5px;
                  "
                >
                  Estar por dentro</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!--Second Spoiller-->

        <v-col lg="3" md="auto">
          <v-card
            class="pa-2 office-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="200"
          >
            <div class="spoiler-size call-wid">
              <strong
                ><v-icon size="60" color="rgb(23, 87, 109)"
                  >mdi-instagram</v-icon
                ></strong
              >
            </div>
            <v-card-text class="spoiler-description call-wid mt-n6">
              <div class="mt-n6" data-aos="fade-up">
                <v-btn
                  @click="instagram"
                  text
                  color="rgb(23, 87, 109)"
                  style="
                    text-transform: none;
                    font-size: 18px;
                    letter-spacing: -0.5px;
                  "
                >
                  Estar por dentro</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <!--Third Spoiller-->
        <v-col lg="3" md="auto">
          <v-card
            class="pa-2 ofmail-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="200"
          >
            <div class="spoiler-size call-wid">
              <strong
                ><v-icon size="60" color="rgb(23, 87, 109)"
                  >mdi-youtube</v-icon
                ></strong
              >
            </div>
            <v-card-text class="spoiler-description call-wid mt-n6">
              <div class="mt-n6" data-aos="fade-up">
                <v-btn
                  @click="youtube"
                  text
                  color="rgb(23, 87, 109)"
                  style="
                    text-transform: none;
                    font-size: 18px;
                    letter-spacing: -0.5px;
                  "
                >
                  Estar por dentro</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!--Four Spoiller-->
        <v-col lg="3" md="auto">
          <v-card
            class="pa-2 ofmail-svg m-txt-center"
            color="#ffffff"
            elevation="0"
            min-height="200"
          >
            <div class="spoiler-size call-wid">
              <strong
                ><v-icon size="60" color="rgb(23, 87, 109)"
                  >mdi-facebook</v-icon
                ></strong
              >
            </div>
            <v-card-text class="spoiler-description call-wid mt-n6">
              <div class="mt-n6" data-aos="fade-up">
                <v-btn
                  @click="facebook"
                  text
                  color="rgb(23, 87, 109)"
                  style="
                    text-transform: none;
                    font-size: 18px;
                    letter-spacing: -0.5px;
                  "
                >
                  Estar por dentro</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <div class="m-top-30"></div>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "SectionMoreSupport",
  methods: {
    linkedin() {
      return this.$store.dispatch("linkedin");
    },
    instagram() {
      return this.$store.dispatch("instagram");
    },
    youtube() {
      return this.$store.dispatch("youtube");
    },
    facebook() {
      return this.$store.dispatch("facebook");
    },
  },
};
</script>

<style scoped>
.sans {
  font-family: "Fira Sans Condensed", sans-serif;
  /*font-family: "Source Sans Pro", sans-serif;*/
  /*color: #3e3e42;*/
  color: #32363b;
  line-height: 1.2;
}
.description {
  font-family: "Source Sans Pro", sans-serif;
  color: #616266;
  line-height: 1;
  font-size: 20px;
}
.spoiler-description {
  font-family: "Source Sans Pro", sans-serif;
  color: #ffffff;
  /*line-height: 1;*/
  font-size: 18px;
}
.title-size {
  font-size: 32px;
}

:root {
  --text-color: #616266;
  --header: #32363b;
  --background: #32363b;
}
.spoiler-size {
  font-size: 30px !important;
}
.call-wid {
  /*max-width: 300px;*/
  padding: 40px;
  font-family: "Source Sans Pro", sans-serif;
  color: rgb(23, 87, 109) !important;
  line-height: 1.2;
}

.bg-more-support {
  background-color: #efefef;
}
</style>
