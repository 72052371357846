<template>
  <div>
    <component-banner-service
      title="Conheça o serviço"
      bold-title="Web Hosting"
      description="Crie, hospede o website da sua empresa sem complicações. O nosso serviço de web e-mail serve para todas as empresas."
      :my-style="dt"
    >
    </component-banner-service>
  </div>
</template>

<script>
import ComponentBannerService from "../../common/ComponentBannerService";
export default {
  name: "HeaderHosting",
  components: { ComponentBannerService },
  data: () => ({
    dt: "background-image: linear-gradient(295deg,rgba(15, 15, 16, 0.68),rgb(0, 0, 0)),url('https://img.freepik.com/free-photo/cloud-computing-banner-background-smart-city_53876-108504.jpg');background-size: cover;background-color: #efefef;",
  }),
};
</script>

<style scoped></style>
