import axios from "axios";

const apiClient = axios.create({
  baseURL: "http://localhost:8001",
  withCredentials: false,
  validateStatus: () => true,
  headers: {
    Accept: "Application/json",
    "Content-Type": "Application/json",
  },
});

export default {
  requestCallToNetSpace(options) {
    return apiClient.post("/api/v1/request/call", options);
  },

  requestInfoOfService(options) {
    return apiClient.post("/api/v1/request/info/service", options);
  },
};
