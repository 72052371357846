import i18n from "./i18n";
import i18nEn from "./i18nEn";

const pt = i18n;
const en = i18nEn;

export class Language {
  setLanguage(lang = 1) {
    if (lang === 1) {
      return pt;
    } else {
      return en;
    }
  }
  getMsg() {
    return this.setLanguage();
  }
}
