<template>
<div>
  <header-net-sat>
  </header-net-sat>
  <more-net-sat></more-net-sat>
  <contact-form-service></contact-form-service>
</div>
</template>

<script>
import HeaderNetSat from "../components/internet/netsat/HeaderNetSat";
import MoreNetSat from "../components/internet/netsat/MoreNetSat";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageNetSat",
  components: {ContactFormService, MoreNetSat, HeaderNetSat}
}
</script>

<style scoped>

</style>