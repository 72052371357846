<template>
<div>
  <header-sip></header-sip>
  <more-sip></more-sip>
  <contact-form-service></contact-form-service>
</div>
</template>

<script>
import HeaderSip from "../components/voice/sip/HeaderSip";
import MoreSip from "../components/voice/sip/MoreSip";
import ContactFormService from "../components/tools/ContactFormService";
export default {
  name: "PageSipTrunk",
  components: {ContactFormService, MoreSip, HeaderSip}
}
</script>

<style scoped>

</style>