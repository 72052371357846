<template>
  <div class="bg-cover" style="height: 400px !important; margin-top: -980px">
    <video
      style="
        min-height: 100%;
        height: 830px;
        min-width: 100%;
        position: relative;
      "
      playsinline
      autoplay
      muted
      loop
    >
      <source
        src="../../../assets/video/enterprise.mp4"
        type="video/mp4"
      />
    </video>
  </div>
</template>

<script>
export default {
  name: "HeaderCover",
};
</script>

<style scoped>
/*.bg-cover {*/
/*  background: ;*/
/*}*/
</style>
