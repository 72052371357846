export class QuestionMath {
  constructor(Language = []) {
    this.msg = Language;
  }

  setOperator() {
    return Math.floor(Math.random() * 2);
  }

  elementaryOperator() {
    return ["+", "-"];
  }

  numberObject() {
    return Math.floor(Math.random() * 20);
  }

  renderResult() {
    //Render aleatory question
    let saveDta;
    let q;
    const firstNumber = this.numberObject();
    const secondNumber = this.numberObject();
    const operator = this.elementaryOperator()[this.setOperator()];
    if (operator === "-") {
      if (firstNumber > secondNumber) {
        q = parseInt(firstNumber) - parseInt(secondNumber);
        saveDta = JSON.stringify({
          form: firstNumber + " " + operator + " " + secondNumber + " ?",
          a: btoa(q)
        });
        return saveDta;
      } else {
        q = parseInt(secondNumber) - parseInt(firstNumber);
        saveDta = JSON.stringify({
          form: secondNumber + " " + operator + " " + firstNumber + " ?",
          a: btoa(q)
        });
        return saveDta;
      }
    } else {
      q = parseInt(firstNumber) + parseInt(secondNumber);
      saveDta = JSON.stringify({
        form: firstNumber + " " + operator + " " + secondNumber + " ?",
        a: btoa(q)
      });
      return saveDta;
    }
  }

  infoString(operator) {
    if (operator === "+") return this.msg.getMsg().sum + " ";
    else return this.msg.getMsg().sub + " ";
  }
}
