<template>
  <div>
    <component-firewall></component-firewall>
  </div>
</template>

<script>
import ComponentFirewall from "../../components/cloud/firewall/ComponentFirewall";
export default {
  name: "PageFirewall",
  components: { ComponentFirewall },
};
</script>

<style scoped></style>
