<template>
  <v-navigation-drawer
    v-model="drawer"
    fixed
    bottom
    temporary
    width="400"
    height="100%"
  >
    <v-list class="font-weight-bold">
      <v-list-item-group
        v-model="group"
        active-class="primary--text darken-4 text--accent-4"
      >
        <v-list-item style="margin-top: 50px" @click="netFast">
          <v-list-item-title
            ><span>NetFast</span>
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="netSat">
          <v-list-item-title
            >NetSat
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="netPremium">
          <v-list-item-title
            >NetPremium
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="voip">
          <v-list-item-title
            >Voip
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="sipTrunk">
          <v-list-item-title
            >Sip Trunk
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>

        <v-list-item @click="ipPbx">
          <v-list-item-title
            >IP PBX
            <v-icon class="text-right" style="float: right"
              >mdi-chevron-right</v-icon
            >
          </v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import eventBus from "../../main";

export default {
  name: "ProductMenu",
  data: () => ({
    drawer: false,
    group: null,
  }),
  methods: {
    myAccount() {
      return this.$store.dispatch("myAccount");
    },
    redirectHome() {
      return this.$router.push("/");
    },

    netFast() {
      return this.$store.dispatch("netFast");
    },
    netSat() {
      return this.$store.dispatch("netSat");
    },

    voip() {
      return this.$store.dispatch("voip");
    },

    sipTrunk() {
      return this.$store.dispatch("sipTrunk");
    },
    netPremium() {
      return this.$store.dispatch("netPremium");
    },

    ipPbx() {
      return this.$store.dispatch("ipPbx");
    },

    firewallInfo() {
      return this.$store.dispatch("firewallInfo");
    },
    antiVirusInfo() {
      return this.$store.dispatch("antiVirusInfo");
    },
    databaseInfo() {
      return this.$store.dispatch("databaseInfo");
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  created() {
    const self = this;
    eventBus.$on("productMenu", function (payload) {
      self.drawer = payload;
    });
  },
};
</script>

<style scoped></style>
